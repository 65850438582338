import { t } from 'i18next';
import React, { ComponentType, Key, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useConditionalEventListener } from '../../../hooks/useConditionalEventListener';
import { PopoverPlacement } from '../../PopoverButton/PopoverButton.styles';
import {
  DropdownContainer,
  DropdownPortal,
  DropdownRow,
  DropdownText,
  DropdownTitle,
  DropdownTrigger,
  PlaceholderText,
  SelectAllText,
  SelectedTextContainer,
  StyledChevronIcon,
} from '../Dropdown.styles';
import { StyledCheckbox } from './DropdownMultiselect.styles';

export interface DropdownMultiselectProps<T, U extends Key> {
  disabled?: boolean;
  title?: string;
  placeholder?: string;
  placement?: PopoverPlacement;
  items: ReadonlyArray<T>;
  selectedKeys: U[];
  keySelector: (item: T) => U;
  selectionComponent?: ComponentType<{ items: T[] }>;
  itemComponent?: ComponentType<{ item: T; selected: boolean }>;
  onSelectionChange: (selection: U[]) => void;
  textSelector?: (item: T) => string;
  enableSelectAllOption?: boolean;
  className?: string;
}

const DropdownMultiselect = <T, U extends Key>({
  disabled,
  title,
  placeholder,
  placement = PopoverPlacement.bottom,
  items,
  selectedKeys,
  keySelector,
  onSelectionChange,
  selectionComponent: Selection,
  itemComponent: Item,
  textSelector,
  enableSelectAllOption,
  className,
}: DropdownMultiselectProps<T, U>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleCheckBoxChange = (checked: boolean, key: U) => {
    if (checked) {
      onSelectionChange(selectedKeys ? [...selectedKeys, key] : [key]);
    } else {
      onSelectionChange(selectedKeys?.filter((value) => value !== key));
    }
  };

  const handleMouseDown = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!triggerRef?.current?.contains(target) && !contentRef?.current?.contains(target)) {
      setIsDropdownOpen(false);
    }
  };

  const handleTab = () => {
    setIsDropdownOpen(false);
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
  };

  const getFocusableElements = () => {
    return contentRef.current?.querySelectorAll(
      'a[href], button, input:not([type="hidden"]):not([disabled]), textarea, select, details, [tabindex]:not([tabindex="-1"])',
    );
  };

  const handleArrowUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    } else {
      const focusableElements = getFocusableElements();
      for (let i = 1; i < focusableElements?.length; i++) {
        if (focusableElements[i] === e.target) {
          (focusableElements[i - 1] as HTMLElement)?.focus();
        }
      }
    }
  };

  const handleArrowDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    } else {
      const focusableElements = getFocusableElements();
      for (let i = 0; i < focusableElements?.length - 1; i++) {
        if (focusableElements[i] === e.target) {
          (focusableElements[i + 1] as HTMLElement)?.focus();
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Tab':
        handleTab();
        break;
      case 'Escape':
        handleEscape(e);
        break;
      case 'ArrowUp':
        handleArrowUp(e);
        break;
      case 'ArrowDown':
        handleArrowDown(e);
        break;
    }
  };

  const handleOutsideScroll = (e: MouseEvent) => {
    if (!contentRef.current?.contains(e.target as HTMLElement)) {
      setIsDropdownOpen(false);
    }
  };

  useConditionalEventListener(isDropdownOpen, 'scroll', handleOutsideScroll, [contentRef.current], true);
  useConditionalEventListener(isDropdownOpen, 'mousedown', handleMouseDown);
  useEffect(() => {
    if (!isDropdownOpen) {
      triggerRef?.current?.focus();
    }
  }, [isDropdownOpen]);

  const handleSelectAll = (allSelected: boolean) => {
    if (allSelected) {
      onSelectionChange([]);
    } else {
      onSelectionChange(items.map((item) => keySelector(item)));
    }
  };

  const DropdownItems = useMemo(() => {
    const selectAllItem = () => {
      const areAllSelected = items?.map(keySelector).every((key) => selectedKeys?.includes(key));
      const selectAllText = t('common.selectAll');
      return (
        <DropdownRow key={'selectAll'} onClick={() => handleSelectAll(areAllSelected)}>
          <StyledCheckbox
            label={<SelectAllText>{selectAllText}</SelectAllText>}
            isSelected={areAllSelected}
            isDisabled
          />
        </DropdownRow>
      );
    };

    const renderedItems = items.map((item) => {
      const key = keySelector(item);
      const isSelected = !!selectedKeys?.includes(key);
      return (
        <DropdownRow key={key} onClick={() => handleCheckBoxChange(!isSelected, key)}>
          {Item ? (
            <Item item={item} selected={isSelected} />
          ) : (
            <StyledCheckbox
              label={<DropdownText>{textSelector?.(item)}</DropdownText>}
              isSelected={isSelected}
              isDisabled
            />
          )}
        </DropdownRow>
      );
    });

    return enableSelectAllOption ? [selectAllItem(), ...renderedItems] : renderedItems;
  }, [items, selectedKeys, keySelector, textSelector, handleCheckBoxChange, enableSelectAllOption]);

  const selectedItems = items.filter((item) => selectedKeys?.includes(keySelector(item)));

  return (
    <DropdownContainer onKeyDown={handleKeyDown} className={className}>
      {title && <DropdownTitle>{title}</DropdownTitle>}
      <DropdownTrigger ref={triggerRef} disabled={disabled} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {Selection ? (
          <Selection items={selectedItems} />
        ) : (
          <>
            <SelectedTextContainer>
              {selectedItems?.length > 0 ? (
                selectedItems.map((item) => textSelector?.(item)).join(', ')
              ) : (
                <PlaceholderText>{placeholder}</PlaceholderText>
              )}
            </SelectedTextContainer>
            <StyledChevronIcon />
          </>
        )}
      </DropdownTrigger>
      <DropdownPortal isOpen={isDropdownOpen} ref={contentRef} triggerRef={triggerRef} placement={placement}>
        {DropdownItems}
      </DropdownPortal>
    </DropdownContainer>
  );
};

export default styled(DropdownMultiselect)`` as typeof DropdownMultiselect;
