import React, { ReactNode, useMemo, useState } from 'react';

import {
  ChevronContainer,
  Container,
  DropdownContainer,
  StyledChevronIcon,
  Title,
  TitleContainer,
  TitleRow,
} from './Collapse.styles';

interface Props {
  title: string | number | JSX.Element;
  defaultOpen?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

const Collapse = ({ title, defaultOpen = false, children, className, disabled }: Props) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  const titleComponent = useMemo(() => {
    return typeof title === 'string' || typeof title === 'number' ? <Title>{title}</Title> : title;
  }, [title]);

  const handleClick = () => {
    !disabled && setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  return (
    <Container className={className}>
      <TitleRow $disabled={disabled} onClick={handleClick} isExpanded={isExpanded}>
        <TitleContainer isExpanded={isExpanded}>
          <ChevronContainer>
            <StyledChevronIcon />
          </ChevronContainer>
          {titleComponent}
        </TitleContainer>
      </TitleRow>
      {isExpanded && <DropdownContainer>{children}</DropdownContainer>}
    </Container>
  );
};

export default Collapse;
