import styled from 'styled-components';

import { ChevronIcon } from '../../assets';
import { focusedOutline } from '../../constants/styles';
import { ThemeProp } from '../../theme/Themes';

export const Title = styled.h5`
  font-weight: ${(props: ThemeProp) => props.theme.fontWeight.bold};
  font-size: ${(props: ThemeProp) => props.theme.fontSize.m};
  line-height: ${(props: ThemeProp) => props.theme.lineHeight.mlg};
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleRow = styled.button<{ isExpanded: boolean; $disabled?: boolean }>`
  background: white;
  width: 41.3125rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.c250};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c100};
  padding: 0.75rem 0;
  border-radius: ${(props) => (props.isExpanded ? '0.5rem 0.5rem 0rem 0rem' : '0.5rem')};
  border-bottom-color: ${(props: { isExpanded: boolean } & ThemeProp) =>
    props.isExpanded ? props.theme.colors.c300 : props.theme.colors.c100};
  flex-shrink: 0;
  ${({ $disabled }) => !$disabled && 'cursor: pointer;'}

  &:focus-visible {
    ${focusedOutline}
  }
  &:hover {
    background: ${(props: ThemeProp) => props.theme.colors.c200};
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TitleContainer = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  ${StyledChevronIcon} {
    stroke: ${(props: ThemeProp) => props.theme.colors.c500};
    transition: all ease-out 0.15s;
    transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 41.3125rem;
  background-color: ${(props: ThemeProp) => props.theme.colors.c250};
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c100};
  padding: 0.75rem 0;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  flex-shrink: 0;
`;

export const ChevronContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  padding: 0rem 0.5rem 0rem 1rem;
`;
